import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ToasterService } from 'src/app/services/toaster.service';
import { RandomUserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  loading = false;
  isConfirmLoading = false;
  constructor(
    private randomUserService: RandomUserService,
    public toasterService: ToasterService,
    private readonly nzModalRef: NzModalRef,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      username: [null, [Validators.required, Validators.email]],
      customer_name: [{value:null,  disabled: true}, [Validators.required]],
      role: [{value:null,  disabled: true}, [Validators.required]],
      status: [null],
    });
    this.getProfile();
  }

  getProfile() {
    this.loading = true;
    return this.randomUserService.getProfile().subscribe({
      next: (data) => {
        console.log(data, 'Profile Data');
        
        this.validateForm.controls['username'].setValue(data.username);
        this.validateForm.controls['customer_name'].setValue(data.customer_name);
        this.validateForm.controls['role'].setValue(data.role);
        // this.validateForm.controls['customer_id'].setValue(data.customer_id);
        this.validateForm.controls['status'].setValue(data.status);
        this.loading = false;
      },
      error: (err) => {
        console.log(err, 'Profile Error');
        this.toasterService.successToaster({
          type: 'error',
          message: 'Something went wrong',
        });
        this.loading = false;
      },
    });
  }

  submitForm() {
    if (this.validateForm.valid) {
      this.isConfirmLoading = true;
      console.log('submit', this.validateForm.value);
      this.randomUserService.updateProfile(this.validateForm.value.username).subscribe({
        next: (data) => {
          console.log(data, 'Profile Data');
          this.isConfirmLoading = false;
          this.getProfile()
         
        },
        error: (err) => {
          
          this.toasterService.successToaster({
            type: 'error',
            message: err.message,
          });
          this.loading = false;
        },
      })
      
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  cancel() {
    this.nzModalRef.close(true);
  }
}
