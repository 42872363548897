import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NgModule } from '@angular/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { IconsProviderModule } from '../../icons-provider.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@NgModule({
  declarations: [],
  imports: [
    NzLayoutModule,
    NzPageHeaderModule,
    NzPopconfirmModule,
    NzBreadCrumbModule,
    NzListModule,
    NzMenuModule,
    IconsProviderModule,
    NzFormModule,
    NzPopoverModule,
    NzRadioModule,
    NzInputModule,
    NzButtonModule,
    NzSpaceModule,
    NzTableModule,
    NzModalModule,
    NzMessageModule,
    NzDropDownModule,
    NzSelectModule,
    NzTypographyModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzSpinModule,
    NzDescriptionsModule,
    NzBadgeModule,
    NzToolTipModule,
    NzDatePickerModule,
    NzCardModule,
    NzDividerModule,
    NzDrawerModule,
    NzCarouselModule,
    NzTabsModule,
    NzTagModule
  ],
  exports: [
    NzLayoutModule,
    NzMenuModule,
    NzPageHeaderModule,
    NzBreadCrumbModule,
    IconsProviderModule,
    NzCollapseModule,
    NzListModule,
    NzPopoverModule,
    NzPopconfirmModule,
    NzFormModule,
    NzSelectModule,
    NzRadioModule,
    NzInputModule,
    NzButtonModule,
    NzSpaceModule,
    NzTableModule,
    NzModalModule,
    NzMessageModule,
    NzDropDownModule,
    NzSelectModule,
    NzTypographyModule,
    NzCheckboxModule,
    NzSpinModule,
    NzDescriptionsModule,
    NzBadgeModule,
    NzToolTipModule,
    NzDatePickerModule,
    NzCardModule,
    NzDividerModule,
    NzDrawerModule,
    NzCarouselModule,
    NzTabsModule,
    NzTagModule
  ],
})
export class NgAntdModule {}
