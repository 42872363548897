import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceAnalyticsService } from 'src/app/services/device-analytics.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
  isCollapsed = false;
  isSmallScreen = false;
  isRegionMenuOpen!: boolean;
  regions!: string[];
  devicesByRegion: { [key: string]: any[] } = {};
  isAdmin!: boolean;
  drawerVisible: boolean=false;

  collapseChange() {
    this.isCollapsed = !this.isCollapsed;
  }
  Pagetitle!: string;
  constructor(private router: Router, private titleService: Title,
    private DeviceRegionsService: DeviceAnalyticsService,
    private authService: AuthService,
    private deviceAnalyticsService: DeviceAnalyticsService,
  ) {}

  ngOnInit() {
    this.checkRole()
    this.checkScreenSize();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`${title}`);
          this.Pagetitle = `${title}`;
        }
      });

      if (this.router.url.startsWith('/device-region')) {
        this.isRegionMenuOpen = true;
        this.getRegions();
      }
      if (this.router.url.startsWith('/device-analysis')) {
        this.isRegionMenuOpen = true;
        this.getRegions();
      }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isSmallScreen = window.innerWidth < 1024; // Example breakpoint for small screens
    !this.isSmallScreen?this.closeDrawer():null
  }
  getRegions() {
    this.DeviceRegionsService.getRegions().subscribe({
      next: (res) => {
        console.log(res);
        this.regions = res;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  openDrawer(): void {
    this.drawerVisible= true;
  }

  closeDrawer(): void {
    this.drawerVisible = false;
  }

  checkRole() {
    if (this.authService.getRole() === 'Admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  getListDevices(region: string) {
    this.deviceAnalyticsService
      .getRegionDevices(region, ['online', 'offline', 'alert_1', 'alert_2'],false)
      .subscribe({
        next: (res) => {
          console.log(res.length, 'Map load');
          this.devicesByRegion[region] = res.map((el) => {
            return {
              ...el,
              device_id: el.device_id.toString(),
            };
          });
          console.log(this.devicesByRegion[region], 'Device list loaded');
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  redirectToMap(region: string) {
    this.closeDrawer()
    this.router.navigate([`/device-region/${region}`]);
  }



}
