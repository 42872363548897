<nz-layout>
  <nz-sider *ngIf="!isSmallScreen" nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
    <app-sidebar
      [collapseVal]="isCollapsed"
      (isCollapsed)="collapseChange()"
    ></app-sidebar>
  </nz-sider>

  <div class="hamburger-icon" (click)="openDrawer()">
    <i nz-icon nzType="menu" nzTheme="outline"></i>
  </div>
  
  <nz-drawer [nzVisible]="drawerVisible" nzPlacement="left" nzClosable=false (nzOnClose)="closeDrawer()">
    <ng-container *nzDrawerContent>
      <img class="drawer-logo" src="../../../assets/Infratech/logo-v1.svg" alt="Logo">

      <ul nz-menu nzTheme="dark" nzMode="inline" nzSelectable="false">
        <li nz-submenu style="padding-left: auto;" [nzOpen]="isRegionMenuOpen" (nzOpenChange)="getRegions()" nzIcon="global" nzTitle="Sensor Regions">
          <ul *ngFor="let region of regions">
            <li nz-submenu routerLinkActive="ant-menu-item-selected" (nzOpenChange)="getListDevices(region)">
              <span title>
                <div style="display: flex; align-items: center;">
                  <span style="color: rgba(255, 255, 255, 0.65)">{{ region }}</span>
                  <button style="background-color: #8080805e; border: none; padding: 5px 5px; margin-left: 5px; font-size: 12px; display: flex; align-items: center; height: 25px; cursor: pointer;" (click)="redirectToMap(region)">
                    View Map
                    <span class="material-icons" style="font-size: 18px; margin-left: 12px">location_on</span>
                  </button>
                </div>
              </span>
              <ul *ngFor="let device of devicesByRegion[region]">
                <li nz-menu-item routerLinkActive="ant-menu-item-selected" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['device-region/analytics', region, device.device_id]" class="nested-list-item" (click)="closeDrawer()">
                  <span class="sidebar-device-name" [nzTooltipTitle]="device.name" [nzTooltipPlacement]="['topLeft', 'leftTop']" nz-button nz-tooltip nzTooltipColor="#131a2d">{{ device.name }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li *ngIf="isAdmin" nz-menu-item routerLinkActive="ant-menu-item-selected" routerLink="/users" (click)="closeDrawer()">
          <span nz-icon nzType="user"></span>
          <span>Users</span>
        </li>
        <li *ngIf="isAdmin" nz-menu-item routerLinkActive="ant-menu-item-selected" routerLink="/devices" (click)="closeDrawer()">
          <span nz-icon nzType="fork"></span>
          <span>Sensors</span>
        </li>
        <li *ngIf="isAdmin" nz-menu-item routerLinkActive="ant-menu-item-selected" routerLink="/alerts" (click)="closeDrawer()">
          <span nz-icon nzType="alert"></span>
          <span>Alerts</span>
        </li>
      </ul>
    </ng-container>
  </nz-drawer>
  
  <nz-layout>
    <app-header
      [pageTitle]="Pagetitle"
      style="position: fixed; z-index: 9;width: -webkit-fill-available; width: -moz-available;"
    ></app-header>
    <nz-content style="margin-top: 50px">
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <app-footer></app-footer>
  </nz-layout>
</nz-layout>
