<nz-table
  nzShowSizeChanger
  [nzData]="list"
  [nzFrontPagination]="false"
  [nzLoading]="loading"
  [nzTotal]="total"
  [nzPageSize]="pageSize"
  [nzPageIndex]="pageIndex"
  (nzQueryParams)="paramsChange($event)"
  nzSize="middle"
  [style.height]="height"
  class="table-striped-rows"
>
<!-- [nzScroll]="{ x: '1150px' }" -->

  <thead>
    <tr>
      <th
        *ngFor="let column of columns"
        [nzColumnKey]="column.key"
        [nzSortFn]="column.compare"
        [nzShowSort]="column.sort"
        [nzWidth]="column.width ? column.width : '200px'"
      >
        {{ column.name }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-content> </ng-content>
  </tbody>
</nz-table>
