import {
  Component,
  AfterViewInit,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import * as moment from 'moment';

interface threshold {
  value: number;
  name: string;
}

@Component({
  selector: 'app-highcharts',
  templateUrl: './high-chart.component.html',
  styleUrls: ['./high-chart.component.css'],
})
export class HighchartsComponent implements OnChanges, AfterViewInit {
  @Input() data: any;
  @Input() precipitation: any;
  @Input() innerWidth: any;
  @Input() thresold!: threshold[];
  @Input() id!: string;
  @Input() name!: string;
  @Input() maxVal!: number;
  @Input() alias!: string;
  @Input() title!: string;
  @Input() deviceId!: string;
  @Input() navigator!: boolean;
  dataUpdated: boolean = false;
  @Output() chartData = new EventEmitter<{
    chartHeight: number;
    lastPointHeight: number;
  }>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.dataUpdated = true;
      console.log('data updated');
    }
  }

  ngAfterViewInit() {
    console.log(
      this.precipitation.map((alert: any) => {
        return { x: alert.x, y: 1 };
      }),
      'prec'
    );

    this.getChartData();
  }
  updateOutputs(chartHeight: any, lastPointHeight: any) {
    this.chartData.emit({ chartHeight, lastPointHeight });
  }
  formatDate(date: any) {
    const jsDate = new Date(date);
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    } as any;
    const formattedDate = jsDate.toLocaleDateString('en-US', options);
    return formattedDate;
  }
  getChartData() {
    const filteredData = this.data.filter((point: any) => !point.z);
    console.log(this.data.length, 'Chart Data length ', this.data);
    const precipitationMax = Math.max(
      ...this.precipitation.map((d: any) => d.y),
      2
    );

    const checkClas = document.getElementsByClassName(
      'highcharts-xaxis-labels'
    );
    let greater_than_max = 0;
    let greater_than_min = 0;
    greater_than_max = Math.max(
      ...this.thresold
        .filter((f) => f.name == 'Greater then max')
        .map((o) => o.value)
    ,0);
    greater_than_min = Math.min(
      ...this.thresold
        .filter((f) => f.name == 'Greater then min')
        .map((o) => o.value,0)
    );
    //  const maxPrec=Math.max(...this.precipitation.map((val:any)=>val.y))
    //  console.log(maxPrec);

    console.log(
      'greater than max ',
      greater_than_max,
      ' greater than min ',
      greater_than_min
    );

    // const max_greater = Math.max(...this.thresold.map((o) => o.value));
    // const min_greater = Math.min(...this.thresold.map((o) => o.value));
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const maxThreshold = Math.max(...this.thresold.map((o) => o.value), 0);
    console.log(this.maxVal, 'Max Val' + ' ' + this.title);
    console.log(maxThreshold, 'Max Threshold' + ' ' + this.title);
    console.log(
      Math.abs(this.maxVal - maxThreshold),
      'Differnce ' + ' ' + this.title
    );
    var timezone = moment.tz.guess();
    console.log(`timezone ${timezone}`);

    const chartOptions: any = {
      time: {
        timezone: timezone,
        useUTC: false,
      },
      chart: {
        width: this.innerWidth < 1500 ? '677' : null,
   
        type: 'area',
        backgroundColor: '#fff',
        height: this.data.length > 0 ? '600px' : '535px',
        plotBorderColor: '#CCCCCC',
        plotBorderWidth: 1,
        events: {
          load: function () {
            console.log("loading");
            
            Highcharts.each(
              checkClas as any,
              function (p: ElementRef | any, i: any) {
                console.log(p.children.length, 'Check P');
                console.log(i, 'Check I');
                for (let index = 0; index < p.children.length; index++) {
                  if (
                    months.some((el) =>
                      p.children[index].textContent.includes(el)
                    )
                  ) {
                    p.children[index].style.color = 'black';
                    p.children[index].style.fontWeight = 'bold';
                    p.children[index].style.fill = 'black';
                  } else {
                    p.children[index].style.color = 'black';
                    p.children[index].style.fill = 'black';
                  }
                }
              }
            );
          },
          redraw: function () {
            console.log("re drawing");
            
            Highcharts.each(
              checkClas as any,
              function (p: ElementRef | any, i: any) {
                console.log(p.children.length, 'Check P');
                console.log(i, 'Check I');
                for (let index = 0; index < p.children.length; index++) {
                  if (
                    months.some((el) =>
                      p.children[index].textContent.includes(el)
                    )
                  ) {
                    p.children[index].style.color = 'black';
                    p.children[index].style.fontWeight = 'bold';
                    p.children[index].style.fill = 'black';
                  } else {
                    p.children[index].style.color = 'black';
                    p.children[index].style.fill = 'black';
                  }
                }
              }
            );
          },
        },
      },
      credits: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
        selected: 0,
        inputEnabled: false,
        labelStyle: {
          display: 'none',
        },
        buttonTheme: {
          fill: 'transparent',
          style: { color: '#000' },
          states: {
            enabled: {
              fill: 'transparent',
              style: {
                color: '#000',
              },
            },
            hover: {
              fill: '#cecfd1',
              style: {
                color: '#000',
              },
            },
            select: {
              fill: '#cecfd1',
              style: {
                color: '#000',
              },
            },
            disabled: {
              fill: 'transparent',
              style: {
                color: 'rgb(102, 102, 102)',
              },
            },
          },
        },
      },
      title: {
        useHTML: true,
        text: `<p style='width: 300px;white-space:normal;margin:0 auto;display: block; height: 50px;"'>${this.name} : ${this.title}</p>`,
      },
      xAxis: {
        type: 'datetime',
        zoomEnabled: true,
        ordinal: true,
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:00',
          day: '%e. %b',
          week: '%e. %b',
          month: "%b '%y",
          year: '%Y',
        },
        plotLines: (() => {
          const plotBands = [];
          let startIndex = null;
          for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].z === true && this.data[i].maintenanceSchedule.start_time) {
              if (startIndex === null) {
                startIndex = this.data[i].x;
              }
              if (i === this.data.length - 1 || this.data[i + 1].z === false) {
                let localTimeStart = moment
                  .utc(this.data[i].maintenanceSchedule.start_time)
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss');
        
                let labelContent:string ;
        
                if (this.data[i].maintenanceSchedule.completed_at) {
                  let localTimeCompleted = moment
                    .utc(this.data[i].maintenanceSchedule.completed_at)
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss');
                  labelContent = `Maintenance: <br>Start: ${localTimeStart}<br>Completed at: ${localTimeCompleted}`;
                } else if (moment.utc().isBefore(this.data[i].maintenanceSchedule.start_time)) {
                  labelContent = `Maintenance: <br>Start: ${localTimeStart}<br>Status: Upcoming`;
                } else {
                  labelContent = `Maintenance: <br>Start: ${localTimeStart}<br>Status: Ongoing`;
                }
        
                plotBands.push({
                  from: startIndex,
                  to: this.data[i].x,
                  color: 'rgba(128, 128, 128, 1)',
                  zIndex: 4,
                  events: {
                    mouseover: function (this: any) {
                      this.svgElem.attr({
                        fill: 'rgba(128, 128, 128, 1)',
                      });
        
                      this.label = this.axis.chart.renderer
                        .label(labelContent, this.to, null, null, null, null, true)
                        .css({
                          color: 'black',
                          backgroundColor: 'rgba(128, 128, 128, 0.4)',
                          padding: '3px',
                        })
                        .attr({
                          fill: 'rgba(128, 128, 128, 0.4)',
                          // zIndex: 1,
                        })
                        .add();
                    },
                    mouseout: function (this: any) {
                      this.svgElem.attr({
                        fill: 'rgba(128, 128, 128, 1)',
                      });
        
                      if (this.label) {
                        this.label.attr({
                          opacity: 0,
                        });
                      }
                    },
                  },
                });
                startIndex = null;
              }
            }
          }
          return plotBands;
        })(),
        
      },
      yAxis: [
        {
          title: {
            text: `Wastewater Level (${this.alias})`,
            style: { color: '#06c55d', fontSize: 15 },
          },
          // showEmpty: false,
          gridLineColor: 'rgb(125 127 131 / 65%)',
          gridLineWidth: 1,
          opposite: false,
          showLastLabel: true,
          max: this.maxVal,
          labels: {
            style: { color: '#06c55d', fontSize: 12 },
          },
          minTickInterval: 1,
          tickAmount: this.id === 'battery' ? 6 : 10,
          minorTickInterval: 1,
          minorTicks: true,
          min: 0,
          plotLines:
            this.thresold.length > 0
              ? this.thresold.map((el) => {
                  let alertText = '';

                  if (el.value === greater_than_max) {
                    alertText = `Surcharge Level - ${el.value}`;
                  } else if (el.value === greater_than_min) {
                    alertText = `Bench Height - ${el.value}`;
                  }

                  let color = '';

                  if (el.value === greater_than_max) {
                    color = 'red';
                  } else if (el.value === greater_than_min) {
                    color = 'orange';
                  }

                  console.log(el, 'Check Alerts');
                  console.log(this.data, 'data is here');
                  return {
                    color: color,
                    width: 3,
                    zIndex: 9,
                    dashStyle:
                      el.value === greater_than_max ? 'ShortDash' : 'Solid',
                    value: el.value,
                    label: {
                      style: {
                        fontSize: 15,
                      },
                      text: alertText,
                      align: 'center',
                      y: 20 /*moves label down*/,
                    },
                  };
                })
              : [],
        },
        {
          title: {
            text: `Precipitation (Inches)`,
            style: { color: '#040273', fontSize: 15 },
          },
          // showEmpty: true,
          max: precipitationMax,
          showLastLabel: true,
          gridLineColor: 'rgb(125 127 131 / 65%)',
          gridLineWidth: 1,
          endOnTick: false,
          opposite: true,
          labels: {
            style: { color: '#040273', fontSize: 12 },
          },
        },
      ],
      legend: {
        enabled: true,
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 1,
              y1: 1,
              x2: 1,
              y2: 1,
            },
            stops: [
              [0, this.title === 'Level' ? '#2d008d99' : '#2d008d99'],
              [
                1,
                (Highcharts as any)
                  .color(this.title === 'Level' ? '#35c35573' : '#35c35573')
                  .setOpacity(1)
                  .get('rgba'),
              ],
            ],
          },

          marker: {
            enabled: true,
            radius: 2,
            states: {
              select: {
                lineColor: '#06c55d',
                fillColor: '#06c55d',
                lineWidth: 2,
                radius: 3,
              },
              hover: {
                lineColor: '#06c55d',
                fillColor: '#06c55d',
                lineWidth: 2,
                radius: 3,
              },
            },
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          lineColor: '#06c55d',
          trackByArea: true,
          pointInterval: 120,
        },
        series: {
          events: {
            legendItemClick: function (event: any) {
              const thisChart = this as any;
              var chart = thisChart.chart;
              setTimeout(function () {
                console.log(chart.series, 'series');

                var primarySeriesVisible = chart.series.some(function (s: any) {
                  return s.name === 'Wastewater Level' && s.visible;
                });
                chart.yAxis[0].plotLinesAndBands.forEach(function (
                  plotLine: any
                ) {
                  plotLine?.svgElem?.attr({
                    visibility: primarySeriesVisible ? 'visible' : 'hidden',
                  });
                  plotLine?.label?.attr({
                    visibility: primarySeriesVisible ? 'visible' : 'hidden',
                  });
                });
              }, 0);
            },
          },
          turboThreshold: 100000, // Set the turboThreshold value as needed
          dataGrouping: {
            enabled: false,
          },
        },
      },
      series: [
        {
          type: 'areaspline',
          name: 'Wastewater Level',
          tooltip: {
            pointFormat: '{series.name} {point.y}',
            valueDecimals: 1,
          },
          data: filteredData,
          color: '#06c55d',
          selected: true,
          visible: true,

          zones: [
            {
              value:
                greater_than_min != 0 ? greater_than_min : greater_than_max,
              fillColor: '#33d07a',
              color: '#06c55d',
            },

            {
              value: greater_than_max,
              fillColor: greater_than_max != 0 ? '#ffbc58' : '#33d07a',
              color: greater_than_max != 0 ? 'orange' : '#06c55d',
            },

            {
              fillColor: greater_than_max != 0 ? '#ff6666' : '#33d07a',
              color: greater_than_max != 0 ? 'red' : '#06c55d',
            },
          ],
          zIndex: 1,
        },
        {
          type: 'column',
          name: 'Precipitation',
          yAxis: 1,
          color: '#040273',
          tooltip: {
            pointFormat: '{series.name} {point.y}',
            valueDecimals: 2,
          },
          data: this.precipitation,
          zIndex: 2,
        },
      ],
      navigator: {
        enabled: true,

        xAxis: {
          type: 'datetime',
          // tickInterval:1,
          minTickInterval: 100,
          tickAmount: this.id === 'battery' ? 6 : 10,
          minorTickInterval: 100,
          minorTicks: true,
        },
      },
      tooltip: {},
      lang: {
        noData: 'No data available',
        rangeSelectorZoom: '',
      },
    };
    console.log('chartOption====>', chartOptions);

    setTimeout(() => {
      const chart = new Highcharts.StockChart(
        `chartContainer-${this.deviceId}`,
        Highcharts.merge(chartOptions),
        (chartData) => {
          const chart = chartData;
          console.log(chart, 'chart check if loaded or not');
          // Accessing the last point's height
          console.log('chart', chart);
          const series = chart.series[0];
          console.log('series', series);

          const lastPoint = series.data[series.data.length - 1];
          // const lastPointY = lastPoint.plotY;
          const lpy = lastPoint?.y;
          const maxValue = chart.yAxis[0]?.max || 0;
          const lph = (lpy! / maxValue) * chart.plotHeight;
          console.log(chart.plotHeight, 'height');
          console.log(lph, 'point height');
          console.log(chart.chartHeight, 'full height');

          this.chartData.emit({
            chartHeight: chart.plotHeight,
            lastPointHeight: lph,
          });
        }
      );
    }, 0);
  }
}
