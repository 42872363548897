<app-table *ngIf="show" [tableData]="listOfRandomUser"
  (loadDataFromServer)="loadDataFromServer(pageIndex, pageSize, null, null, [], offset, searchValue, 0)" [total]="total"
  [loading]="loading" [pageSize]="pageSize" [pageIndex]="pageIndex" (onParamsChange)="onQueryParamsChange($event)"
  [columns]="columns" height="100%">
  <tr *ngFor="let data of listOfRandomUser">
    <td>{{ data.datetime   | date: "medium" }}</td>
    <td>{{ data.value }} In</td>
    <!-- To be deploy on next update -->
    <!-- <td>{{data.benchLevelThreshold || 'N/A'}}</td>
      <td>{{data.benchLevelTriggered || 'N/A'}}</td>
      <td>{{data.surchargeLevelThreshold || 'N/A'}}</td>
      <td>{{data.surchargeLevelTriggered || 'N/A'}}</td> -->
    <!-- <td *ngIf="columns.length > 3">{{data.alert_1 ? data.alert_1.threshold_limit : 0}}</td> -->
    <!-- <td *ngIf="columns.length > 3">{{data.alert_1 ? data.alert_1.triggered ? 'Yes' : 'No' : 'No'}}</td> -->
    <!-- <td *ngIf="columns.length > 4">{{data.alert_2 ? data.alert_2.threshold_limit : 0}}</td> -->
    <!-- <td *ngIf="columns.length > 4">{{data.alert_2 ? data.alert_2.triggered ? 'Yes' : 'No' : 'No'}}</td> -->
  </tr>
</app-table>

<div *ngIf="listOfRandomUser.length>0" class="pagination-container">
  <button nz-button [disabled]="pageIndex <= 1 || disableBtn" (click)="onPrevPage()">
    Previous
  </button>
  <span class="current-page">{{ pageIndex }}</span>
  <button nz-button [disabled]="!hasNextPage || disableBtn" (click)="onNextPage()">
    Next
  </button>
</div>