<app-table 
*ngIf="show"
    [tableData]="listOfRandomUser"
    (loadDataFromServer)="
      loadDataFromServer(this.pageIndex, this.pageSize, null, null, [], offset, searchValue)
    "
    [total]="total"
    [loading]="loading"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    (onParamsChange)="onQueryParamsChange($event)"
    [columns]="columns"
    height="100%"
  >
    <tr *ngFor="let data of listOfRandomUser">
      <td>{{data.created_at ? (momentz.utc(data.created_at).tz(timezone).format() | date: 'medium') : 'N/A'}} </td>
      <td>{{data.start_time ? (momentz.utc(data.start_time).tz(timezone).format() | date: 'medium') : 'N/A'}} </td>
      <td>{{data.startedBy}} </td>
      <td>{{data.completed_at ? (momentz.utc(data.completed_at).tz(timezone).format() | date: 'medium') : 'N/A'}} </td>
      <td>{{data.completedBy}} </td>
      <td>{{formatString(data.status)}} </td>
      <td>
        <button (click)="openRemarksModal(data.remarks)" class="remarks_btn">Remarks</button>
      </td>
    </tr>
</app-table>
