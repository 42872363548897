import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { User } from '../types/userType';
import { environment } from '../../environments/environment';
import { CustomError } from './error.service';

interface userApi {
  entryPoint: User[];
  count: number;
}

@Injectable({ providedIn: 'root' })
export class RandomUserService {
  // randomUserUrl = 'https://api.randomuser.me/';
  apiUrl = environment.api_url;

  // getUsers(
  //   pageIndex: number,
  //   pageSize: number,
  //   sortField: string | null,
  //   sortOrder: string | null,
  //   filters: Array<{ key: string; value: string[] }>
  // ): Observable<{ results: user[] }> {
  //   let params = new HttpParams()
  //     .append('page', `${pageIndex}`)
  //     .append('results', `${pageSize}`)
  //     .append('sortField', `${sortField}`)
  //     .append('sortOrder', `${sortOrder}`);
  //   filters.forEach(filter => {
  //     filter.value.forEach(value => {
  //       params = params.append(filter.key, value);
  //     });
  //   });
  //   return this.http
  //     .get<{ results: user[] }>(`${this.randomUserUrl}`, { params })
  //     .pipe(catchError(() => of({ results: [] })));
  // }

  getUsers(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    offset: number,
    search: string
  ): Observable<userApi> {
    let params = new HttpParams()
      .append('limit', `${pageSize}`)
      .append('offset', `${offset}`)
      .append('sort', `${sortOrder}`)
      .append('attr', `${sortField}`)
      .append('search', `${search}`)
      .append('page', `${pageIndex}`)
    filters.forEach((filter) => {
      filter.value.forEach((value) => {
        params = params.append(filter.key, value);
      });
    });

    return this.http
      .get<userApi>(`${this.apiUrl}/api/User`, { params })
  }

  addUser(user: {
    username: string;
    password: string;
    customer_id: number;
    status: string;
  }): Observable<User> {
    return this.http
      .post<User>(`${this.apiUrl}/api/User`, user)
  }
  getSingleUser(id: string): Observable<User> {
    return this.http
      .get<User>(`${this.apiUrl}/api/User/${id}`)
  }
  updateUser(id: number, user: any): Observable<User> {
    return this.http
      .put<User>(`${this.apiUrl}/api/User/updateSuperAdmin/${id}`, user)
  }

  getProfile(){
    return this.http.get<any>(`${this.apiUrl}/api/User/getProfile/`)

   }


  createSuspension(data: any): Observable<any> {
    console.log('Creating suspension:', data);
    return this.http
    .post(`${this.apiUrl}/api/maintenanceSchedule`, data)
  }
  deleteUser(id:number): Observable<User>{
    return this.http.delete<User>(`${this.apiUrl}/api/User/${id}`, {})
    // .pipe(catchError(this.showError.handleError));
   }

  updateSuspension(id: string, data: any): Observable<any> {
    console.log('Updating suspension:', id, data);
    return this.http
      .put(`${this.apiUrl}/api/maintenanceSchedule/${id}`, data)
  }
  resetSuspension(id: string,payload:any): Observable<any> {
    console.log('reseting suspension:', id);
    return this.http
      .put(`${this.apiUrl}/api/maintenanceSchedule/reset/${id}`,payload)
  }

  getSuspensionDetail(id: string): Observable<any> {
    console.log('Getting suspension detail:', id);
    return this.http
      .get(`${this.apiUrl}/api/maintenanceSchedule/${id}`)

  }

   updateProfile(username: string){
    return this.http.put(`${this.apiUrl}/api/User/UpdateUsername/${username}`,{})
   }



  constructor(private http: HttpClient, public showError: CustomError) {}
}
