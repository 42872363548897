import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProfileComponent } from 'src/app/pages/user/profile/profile.component';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input() pageTitle!: string;

  username: string = '';
  customerName: string = '';

  logout() {
    const hash =localStorage.getItem('hash_token');
    
 

    this.authService.deleteJwt(hash as any).subscribe({
      next:(res)=>
      {
   
        localStorage.removeItem('hash_token');
       this.authService.logout();
      window.location.href = environment['redirect_url'];
      },
      error: (err) => {
        console.log(err);
       
      },
    })

  }

  getUserProfile() {
    const user = localStorage.getItem('user');
    const displayUser = user ? JSON.parse(user) : {};
    this.username = displayUser ? displayUser.username.split('@')[0] : '';
    this.customerName = displayUser ? displayUser.customer_name : '';
  }

  updateUserProfileModal(){
    const modalRef = this.nzModalService.create({
      nzTitle: 'Profile',
      nzContent: ProfileComponent,
      nzFooter: null,
      // nzComponentParams: {
      //   action: 'edit',
      //   id: id,
      // } as any,
    });
  }

  constructor(private readonly authService: AuthService, private readonly nzModalService: NzModalService) {}

  ngOnInit(): void {
    this.getUserProfile();
  }
}
