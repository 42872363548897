import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError, switchMap, EMPTY } from 'rxjs';
import { CustomError } from './services/error.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  
  constructor(
    private authService: AuthService,
    private showError: CustomError
  ) {}

  
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getAccessToken();
    const refreshToken = localStorage.getItem('refresh_token');
    const hashToken = localStorage.getItem('hash_token')

    if (accessToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `bearer ${accessToken}`,
        },
      });
    }
 
    return next.handle(req).pipe(
        
      catchError((err) => {
     //   window.location.href = 'https://www.infratechinc.com/';
        console.log(err);
        if (err.status === 401 || err.status === 501) {
          return this.tryRefreshToken(refreshToken!, hashToken!, req, next);
        }
        const error = err.error.message || err.error.text || err.error;
        return throwError(error);
      })
    );
  }
  
  private tryRefreshToken(refreshToken: string, hash:string ,req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!refreshToken) {
      // No refresh token available, handle error (e.g., logout)
      return throwError(new Error('Refresh failed: No refresh token'));
    }
  
    // Call backend rearesh API (consider using a separate http service for refresh)
    return this.authService.refreshToken(refreshToken, hash).pipe(
      switchMap((newAccessToken: string) => {
        // Refresh successful, update access token and retry request
        localStorage.setItem('access_token', newAccessToken);
        req = req.clone({
          setHeaders: {
            Authorization: `bearer ${newAccessToken}`,
          },
        });
        return next.handle(req);
      }),
      catchError((refreshError) => {
        
        // Refresh failed, handle error (e.g., logout)
        this.authService.logout(); 
        console.log("Refresh failed: " + refreshError.message);
        return EMPTY;
      })
    );
  }
}
