<app-table 
*ngIf="show"
    [tableData]="listOfRandomUser"
    (loadDataFromServer)="
      loadDataFromServer(this.pageIndex, this.pageSize, null, null, [], offset, searchValue)
    "
    [total]="total"
    [loading]="loading"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    (onParamsChange)="onQueryParamsChange($event)"
    [columns]="columns"
    height="100%"
  >
    <tr *ngFor="let data of listOfRandomUser">
      <td>{{momentz.utc(data.timestamp).tz(timezone).format()  | date: "medium"}} </td>
      <td>{{data.acknowledged?'Yes':'No'}} </td>
      <td>{{data.acknowledgedBy??'N/A'}} </td>
      <td>{{!data.acknowledgedAt?'N/A':momentz.utc(data.acknowledgedAt).tz(timezone).format()  | date: "medium"}} </td>
    </tr>
  </app-table>
