import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { AuthGuard } from './auth.guard';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { environment } from 'src/environments/environment';
import { RedirectGuard } from './redirect.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [RedirectGuard],
    component: NotFoundComponent,
    data: {
      externalUrl: environment['redirect_url'],
    },
    pathMatch: 'full',
  }, 
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },  
  {
    path: 'redirect',
    component: RedirectComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/user/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard],
        data: {
          role: ['Admin'],
        },
      },
      {
        path: 'devices',
        loadChildren: () =>
          import('./pages/device/device.module').then((m) => m.DeviceModule),
        canActivate: [AuthGuard],
        data: {
          role: ['Admin'],
        },
      },
      {
        path: 'device-region',
        loadChildren: () =>
          import('./pages/device-region/device-region.module').then(
            (m) => m.DeviceRegionModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ['Admin', 'User'],
        },
      },
      {
        path: 'device-history',
        loadChildren: () =>
          import('./pages/device-history/device-history.module').then(
            (m) => m.DeviceHistoryModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ['Admin', 'User'],
        },
      },
      {
        path: 'device-logs',
        loadChildren: () =>
          import('./pages/logs/logs.module').then(
            (m) => m.LogsModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ['Admin', 'User'],
        },
      },
      {
        path: 'alertTable',
        loadChildren: () =>
          import('./pages/alerts/alerts.module').then(
            (m) => m.AlertsModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ['Admin'],
        },
      },
      {
        path: 'alerts',
        loadChildren: () =>
          import('./pages/alertsTable/alertsTable.module').then(
            (m) => m.AlertsTableModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ['Admin'],
        },
      }
    ]
  },
  {
    path: '**',
    canActivate: [RedirectGuard],
    component: NotFoundComponent,
    data: {
      externalUrl: environment['redirect_url'],
    },
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
