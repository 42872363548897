import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceAnalyticsService } from 'src/app/services/device-analytics.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  @Output()
  isCollapsed = new EventEmitter<boolean>();
  @Input()
  collapseVal!: boolean;

  isAdmin!: boolean;
  regions: string[] = [];
  devicesByRegion: { [key: string]: any[] } = {};
  isRegionMenuOpen: boolean = false;
  listDevices!: any[];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private DeviceRegionsService: DeviceAnalyticsService,
    private router: Router,
    private deviceAnalyticsService: DeviceAnalyticsService,

  ) { }

  ngOnInit(): void {
    this.checkRole();
    if (this.router.url.startsWith('/device-region')) {
      this.isRegionMenuOpen = true;
      this.getRegions();
    }
    if (this.router.url.startsWith('/device-analysis')) {
      this.isRegionMenuOpen = true;
      this.getRegions();
    }
    console.log(this.router.url, 'Aactivated Route');
    console.log(location.href, 'Router');
  }

  checkRole() {
    if (this.authService.getRole() === 'Admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  isCollapseFunc(value: boolean) {
    this.isCollapsed.emit(value);
    console.log(this.collapseVal);
  }

  getRegions() {
    this.DeviceRegionsService.getRegions().subscribe({
      next: (res) => {
        console.log(res);
        this.regions = res;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getListDevices(region: string) {
    this.deviceAnalyticsService
      .getRegionDevices(region, ['online', 'offline', 'alert_1', 'alert_2'],false)
      .subscribe({
        next: (res) => {
          console.log(res.length, 'Map load');
          this.devicesByRegion[region] = res.map((el) => {
            return {
              ...el,
              device_id: el.device_id.toString(),
            };
          });
          console.log(this.devicesByRegion[region], 'Device list loaded');
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  redirectToMap(region: string) {
    this.router.navigate([`/device-region/${region}`]);
  }

}
