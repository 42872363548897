import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Customer } from '../types/customerTypes';
import { environment } from '../../environments/environment';
import { catchError, Observable, retry } from 'rxjs';
import { CustomError } from './error.service';
import { Region } from '../types/regions.model';

@Injectable({
  providedIn: 'root',
})
export class DeviceAnalyticsService {
  apiUrl = environment.api_url;

  constructor(private http: HttpClient) {}

  getDeviceHistory(deviceId: number, fromDate: string, toDate:string, tags:string[]): Observable<any> {
    // let params = new HttpParams()
    //   .append('property', `${property}`)
    //   .append('results', `${pageSize}`)
    //   .append('sortField', `${sortField}`)
    //   .append('sortOrder', `${sortOrder}`);
    return this.http.get<any>(
      `${this.apiUrl}/api/DeviceHistory/${deviceId}${tags.map((el, i) => i === 0 ? `?tags=${el}` : `&tags=${el}`).join('')}&from_date=${fromDate}&to_date=${toDate}`
    );
  }

  getRegions(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/api/DeviceHistory/byRegion`);
  }
  getLatestAlert(type: any,deviceIds:any=[],region:string): Observable<Customer> {
    return this.http
      .get<any>(`${this.apiUrl}/api/alerts/latest?type=${type}&device_ids=${deviceIds}&region=${region}`)
  }
  getLatestMaintenance(type: any,deviceIds:any=[],region:string): Observable<Customer> {
    return this.http
      .get<any>(`${this.apiUrl}/api/maintenanceSchedule/latest?device_ids=${deviceIds}&region=${region}`)
  }
  getRegionDevices(region: string, status: string[],tiltFilter:boolean): Observable<Region[]> {
    return this.http.get<Region[]>(
      `${this.apiUrl}/api/DeviceHistory/deviceByRegion?region=${region}${status.map((el) => `&status=${el}&tiltFilter=${tiltFilter}`).join('')}`
    );
  }
}
