<nz-sider nzCollapsible [(nzCollapsed)]="collapseVal" [nzTrigger]="null" style="position: fixed;">
  <div class="logo">
    <div *ngIf="collapseVal; then logov1; else logov2">here is ignored</div>
    <ng-template #logov1
      ><img src="../../../assets/Infratech/logo-v2.svg"
    /></ng-template>
    <ng-template #logov2
      ><img src="../../../assets/Infratech/logo-v1.svg" />
    </ng-template>
  </div>
  <span
    class="trigger menu-icon"
    nz-icon
    [nzType]="collapseVal ? 'right' : 'left'"
    (click)="isCollapseFunc(collapseVal)"
    [style]="collapseVal ? 'margin-left:80%' : 'margin-left: 91%;'"
  ></span>
  <ul nz-menu nzTheme="dark" nzMode="inline" nzSelectable="false">
    <!-- <li
      nz-menu-item
      routerLinkActive="ant-menu-item-selected"
      routerLink="/dashboard"
    >
      <span nz-icon nzType="dashboard"></span>
      <span>Dashboard</span>
    </li> -->
    <li
      nz-submenu
      [nzOpen]="isRegionMenuOpen"
      (nzOpenChange)="getRegions()"
      nzIcon="global"
      nzTitle="Sensor Regions"
    >
    <ul *ngFor="let region of regions">
      <li
        nz-submenu
        routerLinkActive="ant-menu-item-selected"
        (nzOpenChange)="getListDevices(region)"
      >
        <span title>
          <div style="display: flex; align-items: center">
            <span style="color: rgba(255, 255, 255, 0.65)">{{ region }}</span>
            <button
              style="
                background-color: #8080805e;
                border: none;
                padding: 5px 5px;
                margin-left: 5px;
                font-size: 12px;
                display: flex;
                align-items: center;
                height: 25px;
                cursor: pointer;
              "
              (click)="redirectToMap(region)"
            >
              View Map
              <span
                class="material-icons"
                style="font-size: 18px; margin-left: 12px"
              >
                location_on
              </span>
            </button>
          </div>
            <span class="custom-icon">
              <!-- <svg
                version="1.0"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="12px"
                height="12px"
                viewBox="0 0 64 64"
                enable-background="new 0 0 64 64"
                xml:space="preserve"
              >
                <g>
                  <path
                    fill="#fff"
                    d="M32,0C18.745,0,8,10.745,8,24c0,5.678,2.502,10.671,5.271,15l17.097,24.156C30.743,63.686,31.352,64,32,64
      s1.257-0.314,1.632-0.844L50.729,39C53.375,35.438,56,29.678,56,24C56,10.745,45.255,0,32,0z M48.087,39h-0.01L32,61L15.923,39
      h-0.01C13.469,35.469,10,29.799,10,24c0-12.15,9.85-22,22-22s22,9.85,22,22C54,29.799,50.281,35.781,48.087,39z"
                  />
                  <path
                    fill="#fff"
                    d="M32,14c-5.523,0-10,4.478-10,10s4.477,10,10,10s10-4.478,10-10S37.523,14,32,14z M32,32
      c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S36.418,32,32,32z"
                  />
                  <path
                    fill="#fff"
                    d="M32,10c-7.732,0-14,6.268-14,14s6.268,14,14,14s14-6.268,14-14S39.732,10,32,10z M32,36
      c-6.627,0-12-5.373-12-12s5.373-12,12-12s12,5.373,12,12S38.627,36,32,36z"
                  />
                </g>
              </svg> -->
            </span>
        </span>
    
        <ul *ngFor="let device of devicesByRegion[region]">
          <li
            nz-menu-item
            routerLinkActive="ant-menu-item-selected"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="['device-region/analytics', region, device.device_id]"
            class="nested-list-item"
          >
            <span
              class="sidebar-device-name"
              [nzTooltipTitle]="device.name"
              [nzTooltipPlacement]="['topLeft', 'leftTop']"
              nz-button
              nz-tooltip
              nzTooltipColor="#131a2d"
              >{{ device.name }}</span
            >
          </li>
        </ul>
      </li>
    </ul>
    </li>
    <li
      *ngIf="isAdmin"
      nz-menu-item
      routerLinkActive="ant-menu-item-selected"
      routerLink="/users"
    >
      <span nz-icon nzType="user"></span>
      <span>Users</span>
    </li>

    <li
      *ngIf="isAdmin"
      nz-menu-item
      routerLinkActive="ant-menu-item-selected"
      routerLink="/devices"
    >
      <span nz-icon nzType="fork"></span>
      <span>Sensors</span>
    </li>
    <li
      *ngIf="isAdmin"
      nz-menu-item
      routerLinkActive="ant-menu-item-selected"
      routerLink="/alerts"
    >
      <span nz-icon nzType="alert"></span>
      <span>Alerts</span>
    </li>
    <!-- <li
      nz-menu-item
      routerLinkActive="ant-menu-item-selected"
      routerLink="/device-history"
    >
      <span nz-icon nzType="laptop"></span>
      <span>Device History</span>
    </li>
    <li
      nz-menu-item
      routerLinkActive="ant-menu-item-selected"
      routerLink="/device-logs"
    >
      <span nz-icon nzType="laptop"></span>
      <span>Device Monitoring</span>
    </li> -->
  </ul>
</nz-sider>
