import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent implements AfterViewInit, OnChanges {
  @ViewChild('progressbar1') progressBar!: ElementRef;

  @Input() percent!: number;
  @Input() value!: number;
  @Input() deviceId!: number;
  @Input() chartHeight!: number;
  @Input() lastPointHeight!: number;
  @Input() min!: number;
  @Input() max!: number;

  rectY: number = 0;
  rectHeight: number = 0;
  animationId: number | null = null;
  private changeCount: number = 0;

  constructor() {}

  ngAfterViewInit(): void {
    this.drawProgressBar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.changeCount < 2 && (changes['percent'] || changes['chartHeight'])) {
      this.drawProgressBar();
      this.changeCount++;
    }
  }

  drawProgressBar(): void {
    if (!this.progressBar) {
      return;
    }

    const ctx = this.progressBar.nativeElement.getContext('2d');
    if (!ctx) {
      return;
    }

    this.progressBar.nativeElement.height = this.chartHeight;

    const rectWidth = 120;
    const targetHeight = (this.chartHeight * this.percent) / 100;
    const duration = 2000;
    const startTime = performance.now();

    const animate = (time: number) => {
      const elapsed = time - startTime;
      const progress = Math.min(elapsed / duration, 1);
      this.rectHeight = targetHeight * progress;
      this.rectY = this.chartHeight - this.rectHeight;

      ctx.clearRect(0, 0, this.progressBar.nativeElement.width, this.progressBar.nativeElement.height);

      const onePointPercent = this.percent / this.value;
      const minPercent = (onePointPercent * this.min) / 100;
      const maxPercent = (onePointPercent * this.max) / 100;

      ctx.beginPath();
      ctx.lineJoin = 'round';
      const fullHeight = this.chartHeight;
      const greenHeight = fullHeight * minPercent;
      const orangeHeight = fullHeight * (maxPercent - minPercent);
      const redHeight = fullHeight * 0.3;

      ctx.fillStyle = '#06c55d';
      if (this.rectHeight <= greenHeight) {
        ctx.fillRect(0, this.rectY, rectWidth, this.rectHeight);
      } else {
        ctx.fillRect(0, fullHeight - greenHeight, rectWidth, greenHeight);
      }

      ctx.fillStyle = 'orange';
      if (this.rectHeight > greenHeight && this.rectHeight <= greenHeight + orangeHeight) {
        ctx.fillRect(0, fullHeight - greenHeight - (this.rectHeight - greenHeight), rectWidth, this.rectHeight - greenHeight);
      } else if (this.rectHeight > greenHeight + orangeHeight) {
        ctx.fillRect(0, fullHeight - greenHeight - orangeHeight, rectWidth, orangeHeight);
      }

      ctx.fillStyle = 'red';
      if (this.rectHeight > greenHeight + orangeHeight) {
        ctx.fillRect(0, this.rectY, rectWidth, this.rectHeight - greenHeight - orangeHeight);
      }

      if (progress < 1) {
        this.animationId = requestAnimationFrame(animate);
      } else {
        this.animationId = null;
      }
    };

    if (this.animationId !== null) {
      cancelAnimationFrame(this.animationId);
    }
    this.animationId = requestAnimationFrame(animate);
  }
}
