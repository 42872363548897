import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isLoggedIn() !== true) {
      window.location.href = environment.redirect_url;
    }
    this.router.events.forEach((event) => {
      console.log(event);
    });

    let url: string = state.url;
    this.checkUserLogin(next, url);

    return true;
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.authService.isLoggedIn()) {
      const userRole = this.authService.getRole();
      console.log(userRole);
      console.log(route.data['role']);
      if (route.data['role'] && route.data['role'].indexOf(userRole) === -1) {
        console.log(route.data['role']);
        this.router.navigate(['/']);
        return false;
      }
     
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }

  constructor(public authService: AuthService, public router: Router) {}
}
