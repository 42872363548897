import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface data {
  type: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private informSource = new Subject();
  toasterSuccess$ = this.informSource.asObservable();

  public successToaster(data: data) {
    this.informSource.next(data);
  }
}
