import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { ToasterService } from '../../../services/toaster.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.css'],
})
export class ToasterComponent implements OnInit {
  public subscription!: Subscription;
  constructor(
    private nzMessageService: NzMessageService,
    private toasterService: ToasterService
  ) {}

  createMessage(type: string, message: string): void {
    this.nzMessageService.create(type, message);
  }

  ngOnInit(): void {
    this.subscription = this.toasterService.toasterSuccess$.subscribe(
      (data: any) => {
        console.log(data);
        this.createMessage(data.type, data.message);
      }
    );
  }
}
