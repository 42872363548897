import { Component, Input, OnInit } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { DeviceHistoryService } from 'src/app/services/device-history.service';
import * as moment from 'moment';
import 'moment-timezone';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-chart-table',
  templateUrl: './chart-table.component.html',
  styleUrls: ['./chart-table.component.css'],
})
export class ChartTableComponent implements OnInit {
  @Input() refresh!: boolean;
  @Input() device_id!: string;
  @Input() totalDepth!: number;
  @Input() startDate!: string;
  @Input() endDate!: string;
  @Input() show: boolean = false;
  @Input() minGreaterRule!: number;
  @Input() minSampleSize!: any;

  title = 'Test Title';
  searchValue: string = '';

  offset: number = 0;
  hasNextPage: boolean = false;
  lastIdFetched: number = 0;
  pageChange: boolean = false;
  disableBtn: boolean = false;
  prevPageStartIndex: number | null = null;
  total: number = 0;
  listOfRandomUser: any[] = [];
  loading = true;
  pageSize = 10;
  pageIndex = 1;

  columns = [
    {
      name: 'Datetime',
      key: 'datetime',
      compare: (a: any, b: any) => a.datetime.localeCompare(b.datetime),
      sort: false,
      width: '20%',
    },
    {
      name: 'Wastewater Level',
      key: 'value',
      compare: false,
      sort: false,
      width: '20%',
    },
  ];

  constructor(private readonly deviceHistoryService: DeviceHistoryService) {}

  ngOnInit(): void {}

  loadDataFromServer(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: Array<{ key: string; value: string[] }>,
    offset: number,
    search: string,
    lastId: number
  ): void {
    console.log("call");
    
    this.loading = true;
    const timezone = moment.tz.guess();

    this.getDeviceHistoryById(
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      offset,
      search,
      lastId
    ).subscribe((newData: any) => {
      this.listOfRandomUser = newData.results.map((el: any) => ({
        ...el,
        value: Number(el.value).toFixed(1),
        datetime: moment.utc(el.datetime).tz(timezone).format(),
      }));

      this.loading = false;
      this.disableBtn = false;
      this.hasNextPage = newData.hasNextPage;
      this.lastIdFetched = newData.lastIdFetched;
      this.prevPageStartIndex = newData.prevPageStartIndex;
    });
  }

  getDeviceHistoryById(
    pageIndex: number,
    pageSize: number,
    sortField: string | null = 'datetime',
    sortOrder: string | null = 'ascend',
    filter: Array<{ key: string; value: string[] }>,
    offset: number,
    search: string,
    lastId: number
  ) {
    return this.deviceHistoryService
      .getDeviceHistoryByID(
        this.device_id,
        pageIndex,
        pageSize,
        sortField,
        sortOrder,
        filter,
        offset,
        search,
        this.startDate,
        this.endDate,
        lastId
      )
      .pipe(
        map((data: any) => {
          this.total = data.count;
          return data;
        })
      );
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.pageChange) {
      return;
    }
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.offset = pageIndex * pageSize - pageSize;
    this.pageIndex = pageIndex;
    this.loadDataFromServer(
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      this.offset,
      this.searchValue,
      0
    );
  }

  onPrevPage(): void {
    this.disableBtn = true;
    this.pageChange = true;
    this.pageIndex--;
    if (this.prevPageStartIndex !== null ||this.pageIndex == 1 ) {
      this.offset =  this.prevPageStartIndex ||0;
      this.loadDataFromServer(
        this.pageIndex,
        this.pageSize,
        null,
        null,
        [],
        this.offset,
        this.searchValue,
        this.pageIndex==1?0: this.prevPageStartIndex||0
      );
    }
  }

  onNextPage(): void {
    this.disableBtn = true;
    this.pageChange = true;
    if (this.hasNextPage) {
      this.offset = this.lastIdFetched;
      this.loadDataFromServer(
        this.pageIndex,
        this.pageSize,
        null,
        null,
        [],
        this.offset,
        this.searchValue,
        this.lastIdFetched
      );
      this.pageIndex++;
    }
  }
}
