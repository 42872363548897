import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CustomError } from './error.service';

interface User {
  username: string;
  password: string;
}

interface VerifyTokenEmail {
  userEmail: string;
  valid: boolean;
  token: string;
}
interface ChangePassword {
  token: string;
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  API_URL = environment.api_url;

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser = {} as any;
  roleAs!: string | null;

  login(user: User) :Observable<any>{
    return this.httpClient.post<any>(
      `${this.API_URL}/api/Login/authenticate`,
      user,
      {
        responseType: 'text' as any,
      }
      
    )
    
  }
  getAccessToken() {
    return localStorage.getItem('access_token');
  }



  isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    let hashToken = localStorage.getItem('hash_token');
    
    return authToken !== null && hashToken!==null  ? true : false;
  }

  logout() {
    let hashToken = localStorage.getItem('hash_token');
    this.httpClient.delete<string>(
      `${this.API_URL}/api/Redirect/logout/${hashToken}`)

    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    
    window.location.href = environment.redirect_url;

    const route = localStorage.getItem('route');
    const route_data = localStorage.getItem('route_data');
    const route_cods = localStorage.getItem('route_cods');

      if(route){
        localStorage.removeItem('route');
      }
      if(route_data){
        localStorage.removeItem('route_data');
      }
      if(route_cods){
        localStorage.removeItem('route_cods')
      }

  }

  getUserProfile(id: number): Observable<any> {
    return this.httpClient
      .get(`${this.API_URL}/api/login/${id}`, { headers: this.headers })
      .pipe(
        map((res: any) => {
          return res || {};
        }),
        catchError(this.handleError)
      );
  }

  handleError(error:any) {
    let errorMessage = '';
    if (error && error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      console.log(error);
      // errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }

  setUser(user: any) {
    this.currentUser = user;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    const user = localStorage.getItem('user');
    if (user) {
      this.currentUser = JSON.parse(user);
    }
    return this.currentUser;
  }

  deleteJwt(hash: string): Observable<string> {
    return this.httpClient.delete<string>(
      `${this.API_URL}/api/Redirect/logout/${hash}`,
      
      {
        responseType: 'text' as any,
      }
    );
  }

  confirmTokenEmail(token: string, email: string): Observable<string> {
    return this.httpClient.get<string>(
      `${this.API_URL}/api/Login/emailConfirmation/${token}/${email}`,
      {
        responseType: 'text' as any,
      }
    );
  }
  getRole() {
    this.getUser();
    if (this.currentUser) {
      this.currentUser.role_id;
      switch (this.currentUser.role_id) {
        case 1: {
          return 'Super Admin';
        }
        case 2: {
          return 'Admin';
        }
        case 3: {
          return 'User';
        }
      }
    }
    return '';
  }
  forgotPassword(email: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.API_URL}/api/Login/EmailVerification/${email}`,
      {
        responseType: 'text' as any,
      }
    );
  }

  getJwt(hashToken: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.API_URL}/api/Redirect/getJwt/${hashToken}`,
      {
        responseType: 'json' as any,
      }
    );
  }


  refreshToken(refersh_token: string, hash_token:string) {
    return this.httpClient.get<any>(
      `${this.API_URL}/api/Login/refresh/${hash_token}/${refersh_token}`
      , {})  // Replace "{}" with any required data for refresh
      .pipe(
        map((response) => {
          // Extract the new access token from the response (assuming it's in the response body)
          const newAccessToken = response.access_token;  // Replace "accessToken" with actual key name
          if (!newAccessToken) {
            throw new Error('Refresh failed: No access token in response');
          }
          localStorage.setItem('access_token', newAccessToken);
          return newAccessToken;
        }),
        catchError((error) => {
          // Handle refresh errors (e.g., network errors, invalid refresh token)
          return throwError(new Error('Refresh failed: ' + error.message));
        })
      );
  }

  


  verifyTokenEmail(token: string, email: string): Observable<VerifyTokenEmail> {
    return this.httpClient.get<VerifyTokenEmail>(
      `${this.API_URL}/api/Login/PassResetToken/${token}/${email}`
    );
  }
  changePassword(userDetail: ChangePassword): Observable<string> {
    return this.httpClient.put<string>(
      `${this.API_URL}/api/Login/PassResetTokenUpdate`,
      userDetail,
      {
        responseType: 'text' as any,
      }
    );
  }

  constructor(private httpClient: HttpClient, public router: Router) {}
}
