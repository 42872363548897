import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgAntdModule } from './ng-antd.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from '../components/table/table.component';
import { ToasterComponent } from '../components/toaster/toaster.component';
import { SearchInputComponent } from '../components/search-input/search-input.component';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { LoaderComponent } from '../components/loader/loader.component';
import { ChartTableComponent } from '../components/chart-table/chart-table.component';
import { ExportModalComponent } from '../../pages/device-region/export-modal/export-modal.component';
import { HighchartsComponent } from '../components/high-chart/high-chart.component';
import { PrecipitationHistogramComponent } from '../components/precipitation-histogram/precipitation-histogram.component';
import { ChartTableTiltComponent } from '../components/chart-table-tilt/chart-table-tilt.component';
import { ChartTableMaintenanceComponent } from '../components/chart-table-maintenance/chart-table-maintenance.component';
@NgModule({
  declarations: [
    TableComponent,
    ToasterComponent,
    SearchInputComponent,
    ProgressBarComponent,
    LoaderComponent,
    ChartTableComponent,
    ChartTableMaintenanceComponent,
    ChartTableTiltComponent,
    HighchartsComponent,
    PrecipitationHistogramComponent
    // ExportModalComponent,
  ],
  imports: [
    CommonModule,
    NgAntdModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    // ExportModalComponent,
  ],
  exports: [
    NgAntdModule,
    FormsModule,
    ReactiveFormsModule,
    TableComponent,
    ToasterComponent,
    SearchInputComponent,
    ChartTableMaintenanceComponent,
    ProgressBarComponent,
    ChartTableTiltComponent,
    LoaderComponent,
    ChartTableComponent,
    HighchartsComponent,
    PrecipitationHistogramComponent
    // ExportModalComponent,
  ],
})
export class SharedModule {}
