import { Component, Input, OnInit, Output } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { RandomUserService } from 'src/app/services/user.service';
import { User } from 'src/app/types/userType';
import * as moment from 'moment';
import 'moment-timezone';
import { DeviceHistoryService } from 'src/app/services/device-history.service';
import { map } from 'rxjs';


@Component({
  selector: 'app-chart-table-maintenance',
  templateUrl: './chart-table-maintenance.component.html',
  styleUrls: ['./chart-table-maintenance.component.css'],
})
export class ChartTableMaintenanceComponent implements OnInit {
  @Input() refresh!: boolean;
  @Input() device_id!: string;
  @Input() totalDepth!: number;
  @Input() startDate!: string;
  @Input() endDate!: string;
  @Input() show: boolean = false;
  @Input() minGreaterRule!: number;
  @Input() minSampleSize!: any;
  constructor(
    private randomUserService: RandomUserService,
    private readonly deviceHistoryService: DeviceHistoryService
  ) { }

  ngOnInit(): void { }

  title = 'Test Title';
  searchValue: string = '';

  content?: string;
  // table fields
  offset: number = 0;
  total: number = 0;
  momentz=moment
  timezone = moment.tz.guess();
  listOfRandomUser: any[] = [];
  loading = true;
  pageSize = 10;
  pageIndex = 1;
  filterGender = [
    { key: 'male', value: 'male' },
    { key: 'female', value: 'female' },
  ];
  
  columns = [
    {
      name: 'Timestamp',
      key: 'timestamp',
      compare: false,
      // compare: (a: User, b: User) => a.username.localeCompare(b.username),
      sort: false,
      width: '20%',
    },
    {
      name: 'Acknowledged',
      key: 'acknowledged',
      compare: (a: any, b: any) => a.datetime.localeCompare(b.datetime),
      sort: false,
      width: '20%',
    },
    {
      name: 'Acknowledged By',
      key: 'acknowledgedBy',
      compare: (a: any, b: any) => a.datetime.localeCompare(b.datetime),
      sort: false,
      width: '20%',
    },
    {
      name: 'Acknowledged At',
      key: 'acknowledgedAt',
      compare: (a: any, b: any) => a.datetime.localeCompare(b.datetime),
      sort: false,
      width: '150px',
    }
    
    // {
    //   name: 'Bench Height',
    //   key: 'level_value_1',
    //   compare: false,
    //   // compare: (a: User, b: User) => a.username.localeCompare(b.username),
    //   sort: false,
    //   width: '15%',
    // },
    // {
    //   name: 'Bench Height Triggered',
    //   key: 'level_1',
    //   compare: false,
    //   // compare: (a: User, b: User) => a.username.localeCompare(b.username),
    //   sort: false,
    //   width: '15%',
    // },
    // {
    //   name: 'Surcharge Level Limit',
    //   key: 'level_2_value',
    //   compare: false,
    //   // compare: (a: User, b: User) => a.username.localeCompare(b.username),
    //   sort: false,
    //   width: '15%',
    // },
    // {
    //   name: 'Surcharge Level Triggered',
    //   key: 'level_2',
    //   compare: false,
    //   // compare: (a: User, b: User) => a.username.localeCompare(b.username),
    //   sort: false,
    //   width: '15%',
    // },
  ];

  formatString(input: string): string {
    const words = input.split('_');

    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    return capitalizedWords.join(' ');
  }

  loadDataFromServer(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: Array<{ key: string; value: string[] }>,
    offset: number,
    search: string
  ): void {
    // this.show = true;
    this.loading = true;


    console.log('this.minGreaterRule ', this.minGreaterRule, 'this.minSampleSize ', this.minSampleSize);

    let _pageIndex: number = pageIndex;
    let _pageSize: number = pageSize;
    let _offset: number = offset;

    // let distanceInInch = (data.entryPoint[0].value / 2.54);
    // let levelInInch = this.totalDepth - distanceInInch;

    // last element
    // let _distanceInInch = (data.entryPoint[data.entryPoint.length - 1].value / 2.54);
    // let _levelInInch = this.totalDepth - _distanceInInch;
    // let _checkVal = _levelInInch;

    if ((!sortOrder || sortOrder === 'descend') || sortOrder === 'ascend') {
      // If distance or level in inche is zero or greater than total depth we have to count 2nd page
      // If mss is define we have to count 2nd page
      // Later decided we can have zero or greater than total depth incident in middle so always count 2nd page
      if (pageIndex === 1) {
        _pageSize = pageSize * pageIndex;
        // _offset = 0;
      }
      if (pageIndex > 1) {
        _pageSize = pageSize * 3;
        _pageIndex = pageIndex + 1;
        _offset = ((pageIndex - 1) * pageSize) - pageSize;
      }
    }
    this.getDeviceHistoryById(
      pageIndex,
      pageSize,
      '',
      'ascend',
      filter,
      offset,
      search,
      true
    ).subscribe((newData: any) => {

     
      this.listOfRandomUser = newData.tilt_data

      this.loading = false;

      // // console.log(new_data, 'Format data return ');
      // const mapData = new_data.map((ol: any) => {
      //   return {
      //     ...ol,
      //     value: Number(
      //       (this.totalDepth - ol.value / 2.54).toFixed(1)
      //     ),
      //   };
      // });
      // // let again_format = this.format_array(mapData);
      // if (mapData[mapData.length - 1].value <= 0) {
      //   mapData.splice(mapData.length - 1, 1);
      // }
      // this.listOfRandomUser =
      //   mapData &&
      //   mapData.map((el: any, i: number) => {
      //     const find_alert_1 = el.alerts.find(
      //       (kl: any) => kl.level == 'alert_1'
      //     );
      //     const find_alert_2 = el.alerts.find(
      //       (kl: any) => kl.level == 'alert_2'
      //     );
      //     return {
      //       ...el,
      //       value: el.value,
      //       datetime: moment.utc(el.datetime).tz(timezone).format(),
      //       alert_1: find_alert_1,
      //       alert_2: find_alert_2,
      //     };
      //   });
    })
  }

  getDeviceHistoryById(
    pageIndex: number,
    pageSize: number,
    sortField: string,
    sortOrder: string | null = 'ascend',
    filter: Array<{ key: string; value: string[] }>,
    offset: number,
    search: string,
    onLoad: boolean = false) {
    return this.deviceHistoryService
      .getTiltHistoryByID(
        this.device_id,
        pageIndex,
        pageSize,
        sortField,
        sortOrder,
        filter,
        offset,
        search,
        this.startDate,
        this.endDate
      )
      .pipe(
        map((data: any) => {
          this.total = data.count; // mock the total data here

          // if (onLoad) {
          //   const filterRuleOne = data.rule.find(
          //     (il: any) => il.value == null && il.name == 'Level 1'
          //   );
          //   const filterRuleTwo = data.rule.find(
          //     (il: any) => il.value == null && il.name == 'Level 2'
          //   );
          //   if (filterRuleOne && !filterRuleTwo) {
          //     console.log(filterRuleOne, 'Filter rule one');
          //     this.columns = this.columns.filter(
          //       (ol) => ol.key != 'level_1' && ol.key != 'level_1_value'
          //     );
          //     // this.columns.slice(0,2)
          //   } else if (filterRuleTwo && !filterRuleOne) {
          //     this.columns = this.columns.filter(
          //       (ol) => ol.key != 'level_2' && ol.key != 'level_2_value'
          //     );
          //     console.log(this.columns, 'Columns');
          //   } else if (filterRuleTwo && filterRuleOne) {
          //     this.columns = this.columns.filter(
          //       (ol) =>
          //         ol.key != 'level_2' &&
          //         ol.key != 'level_1' &&
          //         ol.key != 'level_1_value' &&
          //         'level_2_value'
          //     );
          //   }
          // }
          return data;
        })
      );
  }

  // format_array(initial_check_data: any[]) {
  //   // Replace next value from previous value if getting 0
  //   for (let i = 0; i < initial_check_data.length; i++) {
  //     if (initial_check_data[i].value <= 0) {
  //       for (let j = i - 1; j >= 0; j--) {
  //         if (initial_check_data[j].value > 0) {
  //           initial_check_data[i].value = initial_check_data[j].value;
  //           break;
  //         }
  //         if (initial_check_data[j].value <= 0 && j == 0) {
  //           initial_check_data[i].value = 0;
  //         }
  //       }
  //     }
  //   }
  //   // Remove starting to index n values if they are zero
  //   for (let i = 0; i < initial_check_data.length; i++) {
  //     if (i == 0 && initial_check_data[i].value > 0) {
  //       break;
  //     } else {
  //       if (
  //         initial_check_data[i].value < 0 ||
  //         initial_check_data[i].value === 0
  //       ) {
  //         console.log('Initial data is zero');
  //         initial_check_data.splice(i, 1);
  //       }
  //       if (initial_check_data[i].value > 0) {
  //         break;
  //       }
  //       // else if(initial_check_data[i].value > 0 && i > 0){
  //       //   console.log(initial_check_data[i], "I should")
  //       //   initial_check_data.splice(0, i - 1)
  //       // }
  //     }
  //   }
  //   return initial_check_data;
  // }

  // Users table pagination function

  onQueryParamsChange(params: NzTableQueryParams): void {
    console.log(params);
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.offset = pageIndex * pageSize - pageSize;
    this.loadDataFromServer(
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      this.offset,
      this.searchValue
    );
  }

}
