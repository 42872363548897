<nz-header class="page-header">
  <div class="navbar">
    <nz-page-header-title>
      <app-breadcrumbs></app-breadcrumbs>
    </nz-page-header-title>
    <div class="nav-items">
      <!-- <span class="user-name" nzPlacement="bottomRight">
        {{ username }}
      </span>
      <span class="user-name" nzPlacement="bottomRight" (click)="logout()">
        Logout
      </span> -->
      <span class="user-name" nzPlacement="bottomRight">
        <span nz-icon nzType="user" nzTheme="outline"></span>
        {{username}}
      </span>
      <span class="user-logout" nzPlacement="bottomRight" (click)="logout()">
        Logout
    
        <span nz-icon nzType="poweroff" nzTheme="outline"></span>
      </span>

      <!-- <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="updateUserProfileModal()">Profile</li>
          <li nz-menu-item (click)="logout()">Log out</li>
        </ul>
      </nz-dropdown-menu> -->
    </div>
  </div>
</nz-header>
