import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceAnalyticsService } from 'src/app/services/device-analytics.service';
import { RandomUserService } from 'src/app/services/user.service';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css'],
})
export class RedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private DeviceRegionsService: DeviceAnalyticsService,
    private router: Router,
    private userService: RandomUserService,
    private authService: AuthService,
    private toasterService: ToasterService
  ) {}

  getRegions() {
    this.DeviceRegionsService.getRegions().subscribe({
      next: (res) => {
        console.log(res,'test');
       
        if (res.length > 0) {
          this.router.navigate([`device-region/${res[0]}`]);
        } else {
          this.toasterService.successToaster({
            type: 'info',
            message: 'You are being logged out as no devices are associated with your account. Please contact support at support@infratechinc.com to register a device.',
          });
          setTimeout(()=>{
            this.router.navigate([`dashboard`]);

          },5000)
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }


  getUserProfile() {
    this.userService.getProfile().subscribe({
      next: (res) => {
        console.log(res);
        const us = {
          ...res,
          role_id:
            res.role == 'Admin'
              ? 2
              : res.role == 'Super Admin'
              ? 1
              : res.role == 'User'
              ? 3
              : null,
        };
        this.authService.setUser(us);
        this.getRegions();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getDecodedAccessToken(token: string): any {
    try {
      this.getUserProfile();
      return jwt_decode(token);
      location.href = environment.redirect_url; 
    } catch (Error) {
      console.log("error")
        location.href = environment.redirect_url; 
           //  window.history.back();
      return null;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      // console.log(params); // { orderby: "price" }
      if (params['token']) {

       this.authService.getJwt(params['token']).subscribe((res) => {
        console.log(res.access_token)
         console.log(res.refresh_token)
         
        console.log(params)
        localStorage.setItem('access_token', res.access_token );
        localStorage.setItem('refresh_token', res.refresh_token );
        localStorage.setItem('hash_token', params['token']);
        this.getDecodedAccessToken(res.access_token);
       },(err) => {
         console.error('Error in validating hash token',err);
         window.location.href = environment.redirect_url;

       })
        // this.authService.setUser(data.user);
      }
    });
  }
}
