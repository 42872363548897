import { Component, OnDestroy } from '@angular/core';
import {HostListener, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy {
  private readonly INACTIVITY_TIMEOUT = 15 * 60 * 1000; 
  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.startInactivityTimer();
  }
  @HostListener('window:mousemove')
  @HostListener('window:keydown')
  resetInactivityTimer(): void {
    clearTimeout(this.inactivityTimer);
    this.startInactivityTimer();
  }
  
  private inactivityTimer: any;

  private startInactivityTimer(): void {
    this.inactivityTimer = setTimeout(() => {
      // Remove the token after the specified inactivity period
    if(localStorage.getItem("access_token")!=null)
    {
      const hash=localStorage.getItem("hash_token");
      localStorage.removeItem("access_token");
      localStorage.removeItem("hash_token");
      console.log(hash);
      this.authService.deleteJwt(hash as any).subscribe({
        next:(res)=>
        {
     
        window.location.href = environment['redirect_url'];
        },
        error: (err) => {
          console.log(err);
         
        },
      })

    }
      
    }, this.INACTIVITY_TIMEOUT);
  }

  ngOnDestroy(): void {
    if(this.inactivityTimer){
      clearTimeout(this.inactivityTimer);
    }
  }
}
