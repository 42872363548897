import {
  Component,
  Injectable,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { checkTableData } from 'src/app/utils/tableheight';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
})
export class TableComponent implements OnInit {
  list!: any[];
  @Input() set tableData(data: any[]){
    this.list = data;
    checkTableData(this.list)
  };
  @Input() total!: number;
  @Input() loading!: boolean;
  @Input() pageSize!: number;
  @Input() pageIndex!: number;
  @Input() filterGender!: Array<{ key: string; value: string }>;
  @Input() columns!: Array<{
    name: string;
    key: string;
    compare: any;
    sort: boolean;
    width?: string;
  }>;
  @Input() height!: string;

  @Output() loadDataFromServer: EventEmitter<{
    pageIndex: number;
    pageSize: number;
    sortField: string | null;
    sortOrder: string | null;
    filter: Array<{ key: string; value: string[] }>;
  }> = new EventEmitter();
  keys!: string[];

  @Output() onParamsChange: EventEmitter<any> = new EventEmitter();

  paramsChange(params: NzTableQueryParams): void {
    this.onParamsChange.emit(params);
  }
 

  constructor() {}

  ngOnInit(): void {
    
  }
}
