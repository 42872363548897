import { Component, AfterViewInit, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-precipitation-histogram',
  templateUrl: './precipitation-histogram.component.html',
  styleUrls: ['./precipitation-histogram.component.css']
})
export class PrecipitationHistogramComponent implements OnChanges, AfterViewInit {
  @Input() precipitation: any;
  @Input() deviceId!: string;
  @Input() display!: string;
  
  dataUpdated: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['precipitation']) {
      this.dataUpdated = true;
      this.getChartData();
    }
  }

  ngAfterViewInit() {
    this.getChartData();
  }

  getChartData() {
    const chartOptions: any = {
      chart: {
        type: 'column',
        backgroundColor: '#fff',
        height: '600px',
        plotBorderColor: '#CCCCCC',
        plotBorderWidth: 1,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Precipitation Histogram'
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%e. %b',
          week: '%e. %b',
          month: "%b '%y",
          year: '%Y',
        },
      },
      yAxis: {
        title: {
          text: 'Precipitation'
        },
        gridLineColor: 'rgb(125 127 131 / 65%)',
        gridLineWidth: 1,
        min: 0,
      },
      series: [
        {
          type: 'column',
          name: 'Precipitation',
          data: this.precipitation,
          color: '#6c8094'
        }
      ],
      tooltip: {
        valueSuffix: ' mm'
      },
      lang: {
        noData: 'No data available'
      }
    };

    setTimeout(() => {
      new Highcharts.Chart(`chartContainer-${this.deviceId}`, Highcharts.merge(chartOptions), (chart) => {
        console.log(chart, 'chart check if loaded or not');
      });
    }, 0);
  }
}
