import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

const currentUrl = window.location.origin;

@Injectable({
  providedIn: 'root',
})
export class CustomError {
  

  constructor(public router: Router, private authService: AuthService) {}

  handleError(error: any) {
    let errorMessage = '';
    let errorStatus: number;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorStatus = error.error.status;
      errorMessage = error.error.message;
    }
    //Model errors from EntityError
    else if (error.error && error.error.errors) {
      errorStatus = error.error.status;
      errorMessage = error.error.errors?.name[0];
    } else if (error && error.message) {
      errorStatus = error.status;
      errorMessage = error.error;
    } else {
      // server-side error
      errorStatus = 501;
      errorMessage = 'Something went wrong';
    }
    console.log(errorStatus);
    console.log(error.error);
    console.log(currentUrl,"Api url")
    if (errorStatus === 401 || errorStatus === 501) { 
      
      localStorage.removeItem('access_token') == null
      window.location.assign(`${currentUrl}/#/`)
      
      
    }
    return throwError(() => {
      return {
        status: errorStatus,
        message: errorMessage,
      };
    });
  }
}
