import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeviceHistory } from '../types/device-history.model';

interface deviceHistoryApi {
  query: DeviceHistory[];
  count: number;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceHistoryService {
  constructor(private http: HttpClient) {}

  apiUrl = environment.api_url;

  getDeviceHistory(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    offset: number,
    search: string,
    from: string,
    to: string
  ): Observable<deviceHistoryApi> {
    let params = new HttpParams()
      .append('take', `${pageSize}`)
      .append('offset', `${offset}`)
      .append('sort', `${sortOrder}`)
      .append('attr', `${sortField}`)
      .append('search', `${search}`)
      .append('from', `${from}`)
      .append('to', `${to}`)
      .append('page', `${pageIndex}`);

    filters.forEach((filter) => {
      filter.value.forEach((value) => {
        params = params.append(filter.key, value);
      });
    });

    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/DeviceHistory/getbymessage`,
      { params }
    );
  }

  getDeviceHistoryByID(
    device_id: string,
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    offset: number,
    search: string,
    from: string,
    to: string,
    startAfter: number
  ) {
    let params = new HttpParams()
      .append('take', `${pageSize}`)
      .append('offset', `${offset}`)
      .append('sort', `${sortOrder}`)
      .append('attr', `${sortField}`)
      .append('search', `${search}`)
      .append('from', `${from}`)
      .append('to', `${to}`)
      .append('page', `${pageIndex}`)
      .append('startAfter', `${startAfter}`);

    filters.forEach((filter) => {
      filter.value.forEach((value) => {
        params = params.append(filter.key, value);
      });
    });
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/DeviceHistory/getMessageHistoryByDeviceId/${device_id}`,
      { params }
    );
  }
  getMaintenanceHistoryByID(
    device_id: string,
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    offset: number,
    search: string,
    from: string,
    to: string
  ) {
    let params = new HttpParams()
      .append('limit', `${pageSize}`)
      .append('offset', `${offset}`)
      .append('sort', `${sortOrder}`)
      .append('attr', `${sortField}`)
      .append('search', `${search}`)
      .append('from', `${from}`)
      .append('to', `${to}`)
      .append('page', `${pageIndex}`);

    filters.forEach((filter) => {
      filter.value.forEach((value) => {
        params = params.append(filter.key, value);
      });
    });
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/MaintenanceSchedule/data/${device_id}`,
      { params }
    );
  }
  getTiltHistoryByID(
    device_id: string,
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    offset: number,
    search: string,
    from: string,
    to: string
  ) {
    let params = new HttpParams()
      .append('take', `${pageSize}`)
      .append('offset', `${offset}`)
      .append('sort', `${sortOrder}`)
      .append('attr', `${sortField}`)
      .append('search', `${search}`)
      .append('from', `${from}`)
      .append('to', `${to}`)
      .append('page', `${pageIndex}`);

    filters.forEach((filter) => {
      filter.value.forEach((value) => {
        params = params.append(filter.key, value);
      });
    });
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/DeviceHistory/tiltdata/${device_id}`,
      { params }
    );
  }

  getDeviceListByID(device_id: string) {
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/DeviceHistory/getListOfFiles/${device_id}?type=wastewater`
    );
  }
  getTiltListByID(device_id: string) {
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/DeviceHistory/getListOfFiles/${device_id}?type=tilt`
    );
  }
  getmaintListByID(device_id: string) {
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/maintenanceSchedule/getListOfFiles/${device_id}?`
    );
  }
  generateFile(device_id: string,to:string, from:string) {
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/DeviceHistory/generateFile/${device_id}?to=${to}&from=${from}&localTimeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    );
  }
  generateFileTilt(device_id: string,to:string, from:string) {
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/DeviceHistory/generateTiltFile/${device_id}?to=${to}&from=${from}&localTimeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    );
  }
  generateFileMaintenance(device_id: string,to:string, from:string) {
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/MaintenanceSchedule/generateFile/${device_id}?to=${to}&from=${from}&localTimeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    );
  }
  generateDownloadFile(device_id: string) {
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/DeviceHistory/generateDownloadLink/${device_id}`
    );
  }
  generateDownloadFileMaintenance(device_id: string) {
    console.log('Hit Alert api');
    return this.http.get<deviceHistoryApi>(
      `${this.apiUrl}/api/MaintenanceSchedule/generateDownloadLink/${device_id}`
    );
  }
}
